import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import CustomerCase from '../components/CustomerCase';
import HomeFeatures from '../components/HomeFeatures';
import VerticalBox from '../components/VerticalBox';
import IconText from '../components/IconText';
import Asus from '../images/asus.svg';

function CustomIconText({ title, icon, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} icon={icon} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string,
};

export default () => (
  <Layout current="solutions" subCurrent="durables">
    <CommonPageBanner
      title="耐消行业解决方案"
      description="耐用消费品是一个客户购买周期较长、客单价较高的行业，随着数字化营销的发展，越来越多的耐消品牌开始发展自己的会员和用户运营体系，用个性化的内容持续地触达和培育客户，从而获取私域流量红利。"
      imgName="dm"
    />
    <TitledVerticalBoxes title="耐消行业面临的营销挑战" sectionId="durables-1">
      <Col lg={6} md={12} xs={12}>
        <VerticalBox
          iconIndex={28}
          title="难以直连消费者"
          description="营销终端和供应链条较为复杂，难以实施D2C直连消费者运营策略"
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <VerticalBox
          iconIndex={27}
          title="终端数据割裂"
          description="线上电商、线下KA、门店、代理/加盟商数据割裂，且难以回流"
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <VerticalBox
          iconIndex={3}
          title="营销活动难做"
          description="缺乏全渠道和全链路营销活动的统一管理平台，市场活动往往需要人工操作"
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <VerticalBox
          iconIndex={29}
          title="缺乏客户运营"
          description="消费链条过长导致客情维护困难，缺乏持续的购后体验管理和客户运营"
        />
      </Col>
    </TitledVerticalBoxes>
    <HomeFeatures
      backgroundRevert
      title="Convertlab重塑耐消行业数字化营销"
      subTitle="覆盖从广告投放、客户数据管理、营销自动化、营销内容和体验、用户运营和销售协同的完整营销运营体系，帮助耐消行业有效解决终端数据整合应用、客户运营、营销活动和D2C的管理。建立CDP+MAP+Ad Hub的全链路数字化营销体系。"
      subTitleClassName="shadowed-card"
    />
    <TitledVerticalBoxes title="丰富的产品功能和扩展服务" thinPadding letterSpace={1} sectionId="durables-2">
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={23}
          title="标签解决方案"
          description="建立体系化的标签体系，提供耐消行业标签解决方案和顾问服务"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={15}
          title="精准用户画像"
          description="通过标签、来源、事件、活跃度等指标形成精准360°客户画像"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={18}
          title="全渠道管理"
          description="实现对耐消行业从线上到线下门店的客户、渠道和数据的统一管理"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={30}
          title="AI营销赋能"
          description="AI智能圈群和聚类分析、智能商品推荐、NBA智能决策"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={17}
          title="会员运营体系"
          description="完善的会员管理和营销体系，实现线上线下会员的统一运营"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={31}
          title="营销自动化"
          description="Always-on自动化营销管理，可实现超大规模的个性化营销互动"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={32}
          title="客户旅程设计"
          description="基于不同客户类型的全生命周期旅程，建立个性化客户运营策略"
        />
      </Col>
      <Col lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={33}
          title="效果广告优化"
          description="Ad Hub 智能广告平台，专业的广告策略和运营服务"
        />
      </Col>
    </TitledVerticalBoxes>
    <CustomerCase
      title="耐消行业客户案例"
      icon={Asus}
      paddingTop={0}
      description="台湾华硕电脑股份有限公司是目前全球第一大主板生产商、全球第三大显卡生产商，同时也是全球领先的3C解决方案提供商之一，致力于为个人和企业用户提供最具创新价值的产品及应用方案。华硕的产品线完整覆盖至笔记本电脑、主板、显卡、服务器、光存储、有线/无线网络通讯产品、LCD、掌上电脑、智能手机等全线3C产品。其中显卡和主板以及笔记本电脑三大产品已经成为华硕的主要竞争实力。"
      challenges={[
        {
          id: 'icon-1',
          iconIndex: 34,
          className: 'square',
          description: '全国门店没有统一的POS系统，一物一码体系未建立，线下门店数据成为盲区，线下门店交易数据无法回收，中心无法准确掌握消费者数据。',
        },
        {
          id: 'icon-2',
          iconIndex: 15,
          className: 'square',
          description: '中心营运部门难以高效赋能全国各经销商，在无精确数据的指导下精准策划营销活动难以开启，客流增加、转化率与客单价提升亦成难题，消费者数据和活动数据持续流失。',
        },
        {
          id: 'icon-3',
          iconIndex: 10,
          className: 'square',
          description: '业务线对应普通门店民众、大学生群体、游戏高端玩家等不同消费群体，如何精细化区分管理以实现精细化人群运营。',
        },
      ]}
      solutions={[
        '针对华硕各地区消费者，增强门店互动游戏体验。运用DM Hub的微页面及活动小程序作为数字化触点，和消费者互动时，同步收集消费者信息。',
        '全国1000+门店店长、区域营销及经销商支持部门，可通过DM Hub快速自主的创建门店的消费者互动及推广活动，并收集门店人、货、场的相关数据，建立每个门店自有的数据管理中心，掌握每一个门店和消费者数据，提升购买转化率。',
        '针对中心营运部门，通过DM Hub将16个分公司的所有微信运营打通和数据统一，进行全国门店数据管理。掌握全国门店运营状况并针对性给予支持。另一方面，通过不同公众号与运营手段的建设，做到了精细人群分层运营。',
      ]}
      effects={[
        {
          id: 'icon-1',
          iconId: 'iconeffect-5',
          content: '数据资产的回流收集，将客户资产从渠道方收回品牌方，与消费者直接产生关联。',
        },
        {
          id: 'icon-2',
          iconId: 'iconeffect-10',
          content: '全触点消费者身份识别，形成全域档案搭建全渠道会员体系，并识别用户生命周期，建立消费者个性化1对1沟通的能力。',
        },
        {
          id: 'icon-3',
          iconId: 'iconeffect-7',
          content: '搭建全触点和交互体系，累积近千万有互动记录的高质量消费者档案记录。',
        },
        {
          id: 'icon-4',
          iconId: 'iconeffect-8',
          content: '通过持续的个性化互动提高了消费者活跃度和黏性，微信新增粉丝平均活跃度提高了4倍，带动官方商城月订单数增加了超过3倍。',
        },
      ]}
    />
  </Layout>
);
