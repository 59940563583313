import React from 'react';
import PropTypes from 'prop-types';
import ColoredIcon from './ColoredIcon';

function VerticalBox({
  img,
  iconIndex,
  title,
  description,
  description2,
  smLayout = '',
  smWhiteSpace = '',
}) {
  return (
    <div className={`vertical-box ${smLayout}`}>
      {iconIndex ? <ColoredIcon iconIndex={iconIndex} /> : <img src={img} alt="vertical" />}
      {title && <span className="title">{title}</span>}
      {description && (
        <span
          className={`description ${!title ? 'normal' : ''} ${smWhiteSpace ? 'no-preLine' : ''}`}
        >
          {description}
        </span>
      )}
      {description2 && <span className="description description2">{description2}</span>}
    </div>
  );
}

VerticalBox.propTypes = {
  img: PropTypes.string,
  iconIndex: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  smLayout: PropTypes.string,
  smWhiteSpace: PropTypes.string,
};

export default VerticalBox;
