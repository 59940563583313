import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import Feature1 from '../images/feature-1.png';
import Feature2 from '../images/feature-2.png';
import Feature3 from '../images/feature-3.png';
// import AdHub from '../images/ad-video-image.png';
import DmHub from '../images/dm-video-image.png';
import AiHub from '../images/ai-video-image.png';
import DataHub from '../images/data-video-image.png';
import Cpm from '../images/cpm-video-image.png';
import Pec from '../images/pec-video-image.png';
import BlockIconText from './BlockIconText';
import Container from './Container';
import ImageText from './ImageText';
import { useSearch } from '../utils/common';

const CONTENT_DATA = {
  1: {
    title: 'Data Hub 客户数据平台',
    mainImageId: 'feature-main-1',
    mainImage: Feature1,
    imgWidth: 409,
    imgHeight: 301,
    href: '/cdp/',
    dataList: [
      '全渠道客户数据自动汇总，智能化脏数据清洗整理，建立客户数据平台',
      '智能化标签和人群分层，形成有效客户洞察，建立360°用户画像',
      '帮助市场和运营人员具备数据整合、洞察和应用能力，提高决策能力',
      '高弹性架构轻松应对亿级数据量，强大的信息安全合规能力',
    ],
  },
  2: {
    title: 'DM Hub 营销自动化平台',
    mainImageId: 'feature-main-2',
    mainImage: Feature2,
    imgWidth: 454,
    imgHeight: 300,
    href: '/dmhub/',
    dataList: [
      '营销自动化运营，减少重复性工作，降低营销成本，提高市场ROI',
      '全新自动流3.0引擎，支持所有营销场景的自动化设置，提高决策效率',
      '企业级UDO（自定义对象）能力，支持泛行业的营销对象多变性',
      '可视化客户旅程编辑，智能化推荐引擎，AI能力方案赋能企业营销',
    ],
  },
  3: {
    title: 'Ad Hub 智能广告平台',
    mainImageId: 'feature-main-3',
    mainImage: Feature3,
    imgWidth: 403,
    imgHeight: 300,
    href: '/adhub/',
    dataList: [
      '营销云+广告：公域私域一体化营销优化，显著提升企业流量运营水平',
      '打通腾讯、字节跳动、京东、百度等全链路广告数据，一站式管理',
      '智能实时人群决策引擎，实现广告自动化投放和智能化ROI效果分析',
      '跨平台广告服务与运营能力，提供数字整合营销策略创意、推广执行及广告代运营服务',
    ],
  },
};

const VIDEO_CONTENT_DATA = {
  1: {
    title: 'DM Hub 营销自动化平台',
    href: '/dmhub/',
    productId: 'dmhub',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/dmhub.mp4',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-dm.jpg',
    videoId: 'cl-dmhub',
    mainImageId: 'feature-main-4',
    mainImage: DmHub,
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    dataList: [
      '灵活性：可根据行业属性和业务特点，灵活配置营销场景',
      '个性化：根据客户属性以及外部变量，进行消息个性化群发和内容个性化呈现',
      '自动化：除了自动化执行，还兼具自动化调优能力',
      '行业化：提炼积累的行业Know How，成为有效的产品能力或服务能力',
    ],
    letterSpacingMap: {
      1: 1,
    },
  },
  2: {
    title: 'Data Hub 客户数据平台',
    href: '/cdp/',
    productId: 'datahub',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/datahub-20220114.mov',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-data-20220114.png',
    videoId: 'cl-datahub',
    mainImageId: 'feature-main-2',
    mainImage: DataHub,
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    dataList: [
      '通过连接器打破数据孤岛状态，进行有效的数据清洗，搭建OneID体系',
      '湖仓一体设计架构之上搭建流批一体的达标计算，以秒级实时性完成“客户画像达标”体系的增强',
      'GDM在数据集成和管理上做到可视化的高效协同，节约数据关联的工作时间',
      '从实施中建立行业知识模型，Plus+的标签体系，支持GDQL灵活查询，实现业务易用性',
      '无缝集成Convertlab最新数据合规管理工具CPM，为数据合规应用树立安全防线',
    ],
    letterSpacingMap: {
      2: 2,
    },
  },
  3: {
    title: 'Ad Hub 智能广告平台',
    href: '/adhub/',
    productId: 'adhub',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/a1d1hub.mp4',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-a1d1.jpg',
    videoId: 'cl-adhub',
    mainImageId: 'feature-main-1',
    // mainImage: AdHub,
    mainImage:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/a1d1-video-image.png',
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    dataList: [
      'DMP可有效沉淀人群数据，结合后链路监测能力，实现前中后链路的数据打通',
      '自带RTA技术，有效激活私域数据价值，提升公域投放效果，实现全渠道媒体管理和优化',
      '隐私计算和联邦学习安全赋能建模能力，合规驱动多方数据的联合优化',
      '运营智能化，快速实现跨渠道的A/B测试与场景实时触发的always-on广告',
    ],
    letterSpacingMap: {
      0: 2, // 数字 2 代表有两个字换到了第2行
    },
  },
  4: {
    title: 'CPM 同意和偏好管理平台',
    href: '/cpm/',
    productId: 'cpm',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/cpm.mp4',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-cpm.jpg',
    videoId: 'cl-cpm',
    mainImageId: 'feature-main-5',
    mainImage: Cpm,
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    dataList: [
      '用户交互：提供多种采集工具并记录授权信息，内容上进行个性化配置和A/B测试',
      '核心服务：提供存储数据、处理数据、规则适配等核心能力，通过数据分析优化有效刺激用户进行授权同意，实现授权同意最佳实践',
      '管理服务：通过规则引擎管理同意数据，进行有效性验证、优先级管理，帮助解决不同渠道同意信息冲突',
      '集成服务：丰富的API接口、消息订阅等开放能力，与其它业务系统进行集成应用',
    ],
  },
  5: {
    title: 'AI Hub 数据智能引擎',
    href: '/aihub/',
    productId: 'aihub',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/aihub.mp4',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-ai.jpg',
    videoId: 'cl-aihub',
    mainImageId: 'feature-main-3',
    mainImage: AiHub,
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    // hideDetailButton: true,
    dataList: [
      '全面赋能MAP、CDP、DMP等平台，全链路、多场景实现降本增效',
      'Next Best Action，针对用户实时行为设计营销策略，有效提高业务指标',
      '商品推荐系统，运用最可靠和前沿的算法，并提供多样化配置的逻辑',
      '特征评分，帮助加深对客户的理解，以此为业务人员带来洞察依据',
    ],
  },
  6: {
    title: 'PEC 隐私计算平台',
    href: '/pechub/',
    productId: 'pechub',
    video: 'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/pechub.mp4',
    cover:
      'https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-pec.png',
    videoId: 'cl-pechub',
    mainImageId: 'feature-main-6',
    mainImage: Pec,
    imgWidth: 493,
    imgHeight: 297,
    noFade: true,
    // hideDetailButton: true,
    dataList: [
      '专业解决方案：专为保障企业在数据共享和流通过程中的隐私安全性而设计',
      'MPC多方安全计算：在不泄露隐私数据的情况下进行数据共享、并发挥出数据应有的价值',
      '联邦学习模式：与广告投放 RTA 能力结合，拉新扩量、丰富模型标签，提升数据价值',
      '匿踪查询：增补企业现有数据标签的同时大大提高数据资产安全性，确保仅得到匹配的查询结果却不留查询痕迹',
    ],
    letterSpacingMap: {
      0: 1,
    },
  },
};

function FeaturesItem({ type = '1', isVideo }) {
  const data = isVideo ? VIDEO_CONTENT_DATA[type] : CONTENT_DATA[type];
  const letterSpacingMap = data.letterSpacingMap || {};
  const search = useSearch();
  return (
    <section className={`image-text-section ${isVideo || type % 2 === 0 ? 'blue' : 'white'}`}>
      <Container>
        <ImageText
          image={data.mainImage}
          imgWidth={data.imgWidth}
          imgHeight={data.imgHeight}
          title={data.title}
          isVideo={isVideo}
          videoId={data.videoId}
          video={data.video}
          noFade={data.noFade}
          cover={data.cover}
          productId={data.productId || ''}
          position={isVideo || type % 2 === 0 ? 'right' : 'left'}
          detailButton={
            !data.hideDetailButton && (
              <Button>
                <a href={data.href + search}>了解详情</a>
              </Button>
            )
          }
        >
          {data.dataList.map((item, index) => (
            <BlockIconText
              className={letterSpacingMap[index] ? `letter-spacing-${letterSpacingMap[index]}` : ''}
              content={item}
              color={index % 2 ? 'green' : 'blue'}
              key={item}
            />
          ))}
        </ImageText>
      </Container>
    </section>
  );
}

FeaturesItem.propTypes = {
  type: PropTypes.string,
  isVideo: PropTypes.bool,
};

export default FeaturesItem;
