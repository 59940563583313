import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Container from './Container';
import TitledVerticalBoxes from './TitledVerticalBoxes';
import VerticalBox from './VerticalBox';
import IconText from './IconText';

function Timeline({ contentList = [] }) {
  if (!contentList.length) return null;
  return (
    <div className="timeline-wrapper">
      <div className="timeline-axis" />
      <div className="timeline-content-wrapper">
        {contentList.map((content, index) => (
          <div key={content} className={`timeline-item ${index % 2 ? 'right' : 'left'}`}>
            <div className="timeline-content">{content}</div>
            <div className="timeline-dot" />
          </div>
        ))}
      </div>
    </div>
  );
}

Timeline.propTypes = {
  contentList: PropTypes.arrayOf(PropTypes.string),
};

const COL_SPAN = {
  3: {
    lg: 8,
    md: 12,
    sm: 24,
  },
  4: {
    lg: 6,
    md: 12,
    sm: 24,
  },
};

function CustomerCase({
  title,
  description,
  icon,
  paddingTop,
  challenges = [],
  solutions = [],
  solutionsNode,
  effects = [],
}) {
  // 计算xl时"客户挑战"每行放置的方块个数
  const colNum = challenges.length % 3 ? 4 : 3;

  return (
    <section
      id="customer-case"
      className="customer-case"
      style={typeof paddingTop === 'number' ? { paddingTop } : null}
    >
      <div className="customer-case-banner">
        <div className="banner-bg" />
        <Container>
          <h3 className="title">{title}</h3>
          <div className="description-wrapper">
            <img src={icon} alt="logo" />
            <p>{description}</p>
          </div>
        </Container>
      </div>
      <TitledVerticalBoxes title="客户挑战" shadowed sectionId="customer-challenges">
        {challenges.map((item) => (
          <Col {...COL_SPAN[colNum]} key={item.id} className={`icon-text-col ${item.className}`}>
            <VerticalBox img={item.icon} iconIndex={item.iconIndex} description={item.description} smLayout="in-row" />
          </Col>
        ))}
      </TitledVerticalBoxes>
      {solutionsNode || (
        <div className="solutions-container">
          <Container>
            <h3 className="title">解决方案</h3>
            <Timeline contentList={solutions} />
            {solutionsNode}
          </Container>
        </div>
      )}
      <div className="effects-container">
        <TitledVerticalBoxes title="项目成效">
          {effects.map((item) => (
            <Col span={24} className="icon-text-col" key={item.id}>
              <IconText {...item} iconSize={80} iconPosition="left" />
            </Col>
          ))}
        </TitledVerticalBoxes>
      </div>
    </section>
  );
}

CustomerCase.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  paddingTop: PropTypes.number,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      className: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  solutions: PropTypes.arrayOf(PropTypes.string),
  solutionsNode: PropTypes.node,
  effects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      content: PropTypes.string,
    })
  ),
};

export default CustomerCase;
