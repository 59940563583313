import React from 'react';
import PropTypes from 'prop-types';

function ColoredIcon({ iconIndex = 1, size = 50 }) {
  const columnIndex = Math.ceil(iconIndex / 20) - 1;
  const rowIndex = iconIndex - columnIndex * 20;
  const positionX = columnIndex * 60;
  const positionY = (rowIndex - 1) * 60;
  const xStr = positionX ? `-${positionX}px` : '0px';
  const yStr = positionY ? `-${positionY}px` : '0px';
  return (
    <div className={`colored-icon-wrapper size-${size}`}>
      <div className="colored-icon" style={{ backgroundPosition: `${xStr} ${yStr}` }} />
    </div>
  );
}

ColoredIcon.propTypes = {
  iconIndex: PropTypes.number,
  size: PropTypes.number,
};

export default ColoredIcon;
