import React from 'react';
import PropTypes from 'prop-types';
import ApplyButton from './ApplyButton';
import Container from './Container';

function CommonPageBanner({ title, title2, description, imgName, maskColor = 'black' }) {
  return (
    <section id="common-page-banner" className={`common-page-banner ${maskColor} ${imgName}`}>
      <div className="mask">
        <Container>
          <h2>{title}</h2>
          {title2 && <h2 className="title2">{title2}</h2>}
          <p>{description}</p>
          <ApplyButton size="large" className="green" buttonPosition="middle">
            申请试用
          </ApplyButton>
        </Container>
      </div>
    </section>
  );
}

CommonPageBanner.propTypes = {
  title: PropTypes.string,
  title2: PropTypes.string,
  description: PropTypes.string,
  imgName: PropTypes.string,
  maskColor: PropTypes.oneOf(['black', 'blue']),
};

export default CommonPageBanner;
