import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import Container from './Container';

function TitledVerticalBoxes({
  title = '',
  subTitle = '',
  shadowed = false,
  blue = false,
  noBottom = false,
  children,
  // paddingTop = 0,
  radius = false,
  sectionId = '',
}) {
  let className = `titled-vertical-boxes ${sectionId}`;
  if (shadowed) {
    className += ' shadowed';
  }
  if (blue) {
    className += ' blue';
  }
  if (noBottom) {
    className += ' no-Bottom';
  }
  if (radius) {
    className += ' radius';
  }
  return (
    <section className={className}>
      <Container>
        <div className="title-wrapper">
          {title && <h3 className={subTitle ? 'has-subtitle' : ''}>{title}</h3>}
          {subTitle && <h4>{subTitle}</h4>}
        </div>
        <Row
          className="icon-boxes"
          // style={{ paddingTop }}
          gutter={shadowed || radius ? { md: 30, sm: 15, xs: 15 } : { md: 0, sm: 15, xs: 15 }}
        >
          {children}
        </Row>
      </Container>
    </section>
  );
}

TitledVerticalBoxes.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  shadowed: PropTypes.bool,
  blue: PropTypes.bool,
  noBottom: PropTypes.bool,
  children: PropTypes.node,
  // paddingTop: PropTypes.number,
  radius: PropTypes.bool,
  sectionId: PropTypes.string,
};

export default TitledVerticalBoxes;
