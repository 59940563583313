import React from 'react';
import PropTypes from 'prop-types';
import FeatureItem from './FeatureItem';
import Container from './Container';

function HomeFeatures({
  title = '真正的一体化营销云产品',
  subTitle = '',
  backgroundRevert,
  // subTitleClassName,
  paddingTop,
  isVideo,
}) {
  return (
    <section
      id="features"
      className={`home-features ${backgroundRevert ? 'revert' : ''}`}
      style={typeof paddingTop === 'number' ? { paddingTop } : null}
    >
      <div className="features-title">
        <Container>
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </Container>
      </div>
      <div className="content-list-wrapper">
        <FeatureItem type="1" isVideo={isVideo} />
        <FeatureItem type="2" isVideo={isVideo} />
        <FeatureItem type="3" isVideo={isVideo} />
        {isVideo && <FeatureItem type="4" isVideo={isVideo} />}
      </div>
    </section>
  );
}

HomeFeatures.propTypes = {
  backgroundRevert: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  // subTitleClassName: PropTypes.string,
  paddingTop: PropTypes.number,
  isVideo: PropTypes.bool,
};

export default HomeFeatures;
