import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

const colConfig = {
  right: {
    text: {
      md: { span: 20, order: 1 },
      sm: { span: 24, order: 2 },
      xs: { span: 24, order: 2 },
    },
    icon: {
      md: { span: 4, order: 2 },
      sm: { span: 24, order: 1 },
      xs: { span: 24, order: 1 },
    },
  },
  left: {
    text: {
      md: { span: 21, order: 2 },
      sm: { span: 20, order: 2 },
      xs: { span: 20, order: 2 },
    },
    icon: {
      md: { span: 3, order: 1 },
      sm: { span: 4, order: 1 },
      xs: { span: 4, order: 1 },
    },
  },
  'left-top': {
    text: {
      md: { span: 21, order: 2 },
      sm: { span: 24, order: 2 },
      xs: { span: 24, order: 2 },
    },
    icon: {
      md: { span: 3, order: 1 },
      sm: { span: 24, order: 1 },
      xs: { span: 24, order: 1 },
    },
  },
  top: {
    text: {
      span: 24,
      order: 2,
    },
    icon: {
      span: 24,
      order: 1,
    },
  },
};
function IconText({ icon = '', iconId, iconSize = 48, iconDom, title = '', content = '', iconPosition = 'right' }) {
  return (
    <div className={`icon-item-wrapper ${iconPosition}`}>
      <Row>
        <Col className="text-col" {...colConfig[iconPosition].text}>
          {title && <h4>{title}</h4>}
          {typeof content === 'string' ? <h5>{content}</h5> : content}
        </Col>
        <Col className="icon-col" {...colConfig[iconPosition].icon}>
          <div className="img-wrapper">{iconDom || (iconId ? <span className={`iconfont white font-${iconSize} ${iconId}`} /> : <img src={icon} alt="icon" />)}</div>
        </Col>
      </Row>
    </div>
  );
}

IconText.propTypes = {
  icon: PropTypes.string,
  iconId: PropTypes.string,
  iconSize: PropTypes.number,
  iconDom: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left-top', 'left', 'top', 'right']),
};

export default IconText;
